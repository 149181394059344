import * as React from 'react';
import classNames from 'classnames';
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';
import ResizeObserver from 'rc-resize-observer';
import { ConfigConsumer } from '../config-provider';
import Breadcrumb from '../breadcrumb';
import Avatar from '../avatar';
import TransButton from '../_util/transButton';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
const renderBack = (prefixCls, backIcon, onBack) => {
    if (!backIcon || !onBack) {
        return null;
    }
    return (<LocaleReceiver componentName="PageHeader">
      {({ back }) => (<div className={`${prefixCls}-back`}>
          <TransButton onClick={(e) => {
        if (onBack) {
            onBack(e);
        }
    }} className={`${prefixCls}-back-button`} aria-label={back}>
            {backIcon}
          </TransButton>
        </div>)}
    </LocaleReceiver>);
};
const renderBreadcrumb = (breadcrumb) => <Breadcrumb {...breadcrumb}/>;
const getBackIcon = (props, direction = 'ltr') => {
    if (props.backIcon !== undefined) {
        return props.backIcon;
    }
    return direction === 'rtl' ? <ArrowRightOutlined /> : <ArrowLeftOutlined />;
};
const renderTitle = (prefixCls, props, direction = 'ltr') => {
    const { title, avatar, subTitle, tags, extra, onBack } = props;
    const headingPrefixCls = `${prefixCls}-heading`;
    const hasHeading = title || subTitle || tags || extra;
    // 如果 什么都没有，直接返回一个 null
    if (!hasHeading) {
        return null;
    }
    const backIcon = getBackIcon(props, direction);
    const backIconDom = renderBack(prefixCls, backIcon, onBack);
    const hasTitle = backIconDom || avatar || hasHeading;
    return (<div className={headingPrefixCls}>
      {hasTitle && (<div className={`${headingPrefixCls}-left`}>
          {backIconDom}
          {avatar && <Avatar {...avatar}/>}
          {title && (<span className={`${headingPrefixCls}-title`} title={typeof title === 'string' ? title : undefined}>
              {title}
            </span>)}
          {subTitle && (<span className={`${headingPrefixCls}-sub-title`} title={typeof subTitle === 'string' ? subTitle : undefined}>
              {subTitle}
            </span>)}
          {tags && <span className={`${headingPrefixCls}-tags`}>{tags}</span>}
        </div>)}
      {extra && <span className={`${headingPrefixCls}-extra`}>{extra}</span>}
    </div>);
};
const renderFooter = (prefixCls, footer) => {
    if (footer) {
        return <div className={`${prefixCls}-footer`}>{footer}</div>;
    }
    return null;
};
const renderChildren = (prefixCls, children) => (<div className={`${prefixCls}-content`}>{children}</div>);
const PageHeader = props => {
    const [compact, updateCompact] = React.useState(false);
    const onResize = ({ width }) => {
        updateCompact(width < 768);
    };
    return (<ConfigConsumer>
      {({ getPrefixCls, pageHeader, direction }) => {
        const { prefixCls: customizePrefixCls, style, footer, children, breadcrumb, className: customizeClassName, } = props;
        let ghost = true;
        // Use `ghost` from `props` or from `ConfigProvider` instead.
        if ('ghost' in props) {
            ghost = props.ghost;
        }
        else if (pageHeader && 'ghost' in pageHeader) {
            ghost = pageHeader.ghost;
        }
        const prefixCls = getPrefixCls('page-header', customizePrefixCls);
        const breadcrumbDom = breadcrumb && breadcrumb.routes ? renderBreadcrumb(breadcrumb) : null;
        const className = classNames(prefixCls, customizeClassName, {
            'has-breadcrumb': breadcrumbDom,
            'has-footer': footer,
            [`${prefixCls}-ghost`]: ghost,
            [`${prefixCls}-rtl`]: direction === 'rtl',
            [`${prefixCls}-compact`]: compact,
        });
        return (<ResizeObserver onResize={onResize}>
            <div className={className} style={style}>
              {breadcrumbDom}
              {renderTitle(prefixCls, props, direction)}
              {children && renderChildren(prefixCls, children)}
              {renderFooter(prefixCls, footer)}
            </div>
          </ResizeObserver>);
    }}
    </ConfigConsumer>);
};
export default PageHeader;
