var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import classNames from 'classnames';
import omit from 'omit.js';
import ResizeObserver from 'rc-resize-observer';
import { ConfigContext } from '../config-provider';
import { throttleByAnimationFrameDecorator } from '../_util/throttleByAnimationFrame';
import { addObserveTarget, removeObserveTarget, getTargetRect, getFixedTop, getFixedBottom, } from './utils';
function getDefaultTarget() {
    return typeof window !== 'undefined' ? window : null;
}
var AffixStatus;
(function (AffixStatus) {
    AffixStatus[AffixStatus["None"] = 0] = "None";
    AffixStatus[AffixStatus["Prepare"] = 1] = "Prepare";
})(AffixStatus || (AffixStatus = {}));
class Affix extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            status: AffixStatus.None,
            lastAffix: false,
            prevTarget: null,
        };
        this.getOffsetTop = () => {
            const { offsetBottom } = this.props;
            let { offsetTop } = this.props;
            if (offsetBottom === undefined && offsetTop === undefined) {
                offsetTop = 0;
            }
            return offsetTop;
        };
        this.getOffsetBottom = () => this.props.offsetBottom;
        this.savePlaceholderNode = (node) => {
            this.placeholderNode = node;
        };
        this.saveFixedNode = (node) => {
            this.fixedNode = node;
        };
        // =================== Measure ===================
        this.measure = () => {
            const { status, lastAffix } = this.state;
            const { onChange } = this.props;
            const targetFunc = this.getTargetFunc();
            if (status !== AffixStatus.Prepare || !this.fixedNode || !this.placeholderNode || !targetFunc) {
                return;
            }
            const offsetTop = this.getOffsetTop();
            const offsetBottom = this.getOffsetBottom();
            const targetNode = targetFunc();
            if (!targetNode) {
                return;
            }
            const newState = {
                status: AffixStatus.None,
            };
            const targetRect = getTargetRect(targetNode);
            const placeholderReact = getTargetRect(this.placeholderNode);
            const fixedTop = getFixedTop(placeholderReact, targetRect, offsetTop);
            const fixedBottom = getFixedBottom(placeholderReact, targetRect, offsetBottom);
            if (fixedTop !== undefined) {
                newState.affixStyle = {
                    position: 'fixed',
                    top: fixedTop,
                    width: placeholderReact.width,
                    height: placeholderReact.height,
                };
                newState.placeholderStyle = {
                    width: placeholderReact.width,
                    height: placeholderReact.height,
                };
            }
            else if (fixedBottom !== undefined) {
                newState.affixStyle = {
                    position: 'fixed',
                    bottom: fixedBottom,
                    width: placeholderReact.width,
                    height: placeholderReact.height,
                };
                newState.placeholderStyle = {
                    width: placeholderReact.width,
                    height: placeholderReact.height,
                };
            }
            newState.lastAffix = !!newState.affixStyle;
            if (onChange && lastAffix !== newState.lastAffix) {
                onChange(newState.lastAffix);
            }
            this.setState(newState);
        };
        // @ts-ignore TS6133
        this.prepareMeasure = () => {
            // event param is used before. Keep compatible ts define here.
            this.setState({
                status: AffixStatus.Prepare,
                affixStyle: undefined,
                placeholderStyle: undefined,
            });
            // Test if `updatePosition` called
            if (process.env.NODE_ENV === 'test') {
                const { onTestUpdatePosition } = this.props;
                if (onTestUpdatePosition) {
                    onTestUpdatePosition();
                }
            }
        };
        // =================== Render ===================
        this.render = () => {
            const { getPrefixCls } = this.context;
            const { affixStyle, placeholderStyle } = this.state;
            const { prefixCls, children } = this.props;
            const className = classNames({
                [getPrefixCls('affix', prefixCls)]: affixStyle,
            });
            let props = omit(this.props, ['prefixCls', 'offsetTop', 'offsetBottom', 'target', 'onChange']);
            // Omit this since `onTestUpdatePosition` only works on test.
            if (process.env.NODE_ENV === 'test') {
                props = omit(props, ['onTestUpdatePosition']);
            }
            return (<ResizeObserver onResize={() => {
                this.updatePosition();
            }}>
        <div {...props} ref={this.savePlaceholderNode}>
          {affixStyle && <div style={placeholderStyle} aria-hidden="true"/>}
          <div className={className} ref={this.saveFixedNode} style={affixStyle}>
            <ResizeObserver onResize={() => {
                this.updatePosition();
            }}>
              {children}
            </ResizeObserver>
          </div>
        </div>
      </ResizeObserver>);
        };
    }
    getTargetFunc() {
        const { getTargetContainer } = this.context;
        const { target } = this.props;
        if (target !== undefined) {
            return target;
        }
        return getTargetContainer || getDefaultTarget;
    }
    // Event handler
    componentDidMount() {
        const targetFunc = this.getTargetFunc();
        if (targetFunc) {
            // [Legacy] Wait for parent component ref has its value.
            // We should use target as directly element instead of function which makes element check hard.
            this.timeout = setTimeout(() => {
                addObserveTarget(targetFunc(), this);
                // Mock Event object.
                this.updatePosition();
            });
        }
    }
    componentDidUpdate(prevProps) {
        const { prevTarget } = this.state;
        const targetFunc = this.getTargetFunc();
        let newTarget = null;
        if (targetFunc) {
            newTarget = targetFunc() || null;
        }
        if (prevTarget !== newTarget) {
            removeObserveTarget(this);
            if (newTarget) {
                addObserveTarget(newTarget, this);
                // Mock Event object.
                this.updatePosition();
            }
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ prevTarget: newTarget });
        }
        if (prevProps.offsetTop !== this.props.offsetTop ||
            prevProps.offsetBottom !== this.props.offsetBottom) {
            this.updatePosition();
        }
        this.measure();
    }
    componentWillUnmount() {
        clearTimeout(this.timeout);
        removeObserveTarget(this);
        this.updatePosition.cancel();
        // https://github.com/ant-design/ant-design/issues/22683
        this.lazyUpdatePosition.cancel();
    }
    // Handle realign logic
    updatePosition() {
        this.prepareMeasure();
    }
    lazyUpdatePosition() {
        const targetFunc = this.getTargetFunc();
        const { affixStyle } = this.state;
        // Check position change before measure to make Safari smooth
        if (targetFunc && affixStyle) {
            const offsetTop = this.getOffsetTop();
            const offsetBottom = this.getOffsetBottom();
            const targetNode = targetFunc();
            if (targetNode && this.placeholderNode) {
                const targetRect = getTargetRect(targetNode);
                const placeholderReact = getTargetRect(this.placeholderNode);
                const fixedTop = getFixedTop(placeholderReact, targetRect, offsetTop);
                const fixedBottom = getFixedBottom(placeholderReact, targetRect, offsetBottom);
                if ((fixedTop !== undefined && affixStyle.top === fixedTop) ||
                    (fixedBottom !== undefined && affixStyle.bottom === fixedBottom)) {
                    return;
                }
            }
        }
        // Directly call prepare measure since it's already throttled.
        this.prepareMeasure();
    }
}
Affix.contextType = ConfigContext;
__decorate([
    throttleByAnimationFrameDecorator()
], Affix.prototype, "updatePosition", null);
__decorate([
    throttleByAnimationFrameDecorator()
], Affix.prototype, "lazyUpdatePosition", null);
export default Affix;
