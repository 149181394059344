import * as React from 'react';
import RcMenu, { Divider, ItemGroup } from 'rc-menu';
import classNames from 'classnames';
import SubMenu from './SubMenu';
import Item from './MenuItem';
import { ConfigConsumer } from '../config-provider';
import devWarning from '../_util/devWarning';
import { SiderContext } from '../layout/Sider';
import collapseMotion from '../_util/motion';
import { cloneElement } from '../_util/reactNode';
import MenuContext from './MenuContext';
export { MenuItemGroupProps } from 'rc-menu';
class InternalMenu extends React.Component {
    constructor(props) {
        super(props);
        this.renderMenu = ({ getPopupContainer, getPrefixCls, direction }) => {
            const { prefixCls: customizePrefixCls, className, theme, expandIcon } = this.props;
            const defaultMotions = {
                horizontal: { motionName: 'slide-up' },
                inline: collapseMotion,
                other: { motionName: 'zoom-big' },
            };
            const prefixCls = getPrefixCls('menu', customizePrefixCls);
            const menuClassName = classNames(`${prefixCls}-${theme}`, {
                [`${prefixCls}-inline-collapsed`]: this.getInlineCollapsed(),
            }, className);
            return (<MenuContext.Provider value={{
                inlineCollapsed: this.getInlineCollapsed() || false,
                antdMenuTheme: theme,
                direction,
            }}>
        <RcMenu getPopupContainer={getPopupContainer} {...this.props} className={menuClassName} prefixCls={prefixCls} direction={direction} defaultMotions={defaultMotions} expandIcon={cloneElement(expandIcon, {
                className: `${prefixCls}-submenu-expand-icon`,
            })}/>
      </MenuContext.Provider>);
        };
        if (process.env.NODE_ENV !== 'production')
            devWarning(!('inlineCollapsed' in props && props.mode !== 'inline'), 'Menu', '`inlineCollapsed` should only be used when `mode` is inline.');
        devWarning(!(props.siderCollapsed !== undefined && 'inlineCollapsed' in props), 'Menu', '`inlineCollapsed` not control Menu under Sider. Should set `collapsed` on Sider instead.');
    }
    getInlineCollapsed() {
        const { inlineCollapsed, siderCollapsed } = this.props;
        if (siderCollapsed !== undefined) {
            return siderCollapsed;
        }
        return inlineCollapsed;
    }
    render() {
        return <ConfigConsumer>{this.renderMenu}</ConfigConsumer>;
    }
}
InternalMenu.defaultProps = {
    className: '',
    theme: 'light',
    focusable: false,
};
// We should keep this as ref-able
export default class Menu extends React.Component {
    render() {
        return (<SiderContext.Consumer>
        {(context) => <InternalMenu {...this.props} {...context}/>}
      </SiderContext.Consumer>);
    }
}
Menu.Divider = Divider;
Menu.Item = Item;
Menu.SubMenu = SubMenu;
Menu.ItemGroup = ItemGroup;
