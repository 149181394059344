import * as React from 'react';
import { useRef } from 'react';
import { composeRef } from 'rc-util/lib/ref';
import raf from 'rc-util/lib/raf';
import Tooltip from '../tooltip';
const SliderTooltip = React.forwardRef((props, ref) => {
    const { visible } = props;
    const innerRef = useRef(null);
    const rafRef = useRef(null);
    function cancelKeepAlign() {
        raf.cancel(rafRef.current);
        rafRef.current = null;
    }
    function keepAlign() {
        rafRef.current = raf(() => {
            innerRef.current.forcePopupAlign();
            rafRef.current = null;
            keepAlign();
        });
    }
    React.useEffect(() => {
        if (visible) {
            keepAlign();
        }
        else {
            cancelKeepAlign();
        }
        return cancelKeepAlign;
    }, [visible]);
    return <Tooltip ref={composeRef(innerRef, ref)} {...props}/>;
});
export default SliderTooltip;
