var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { List } from 'rc-field-form';
import devWarning from '../_util/devWarning';
import { ConfigContext } from '../config-provider';
import { FormItemPrefixContext } from './context';
const FormList = (_a) => {
    var { prefixCls: customizePrefixCls, children } = _a, props = __rest(_a, ["prefixCls", "children"]);
    if (process.env.NODE_ENV !== 'production')
        devWarning(!!props.name, 'Form.List', 'Miss `name` prop.');
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('form', customizePrefixCls);
    return (<List {...props}>
      {(fields, operation, meta) => (<FormItemPrefixContext.Provider value={{ prefixCls, status: 'error' }}>
          {children(fields.map(field => (Object.assign(Object.assign({}, field), { fieldKey: field.key }))), operation, {
        errors: meta.errors,
    })}
        </FormItemPrefixContext.Provider>)}
    </List>);
};
export default FormList;
