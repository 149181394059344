var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import { ConfigContext } from '../config-provider';
export const LayoutContext = React.createContext({
    siderHook: {
        addSider: () => null,
        removeSider: () => null,
    },
});
function generator({ suffixCls, tagName, displayName }) {
    return (BasicComponent) => {
        const Adapter = props => {
            const { getPrefixCls } = React.useContext(ConfigContext);
            const { prefixCls: customizePrefixCls } = props;
            const prefixCls = getPrefixCls(suffixCls, customizePrefixCls);
            return <BasicComponent prefixCls={prefixCls} tagName={tagName} {...props}/>;
        };
        Adapter.displayName = displayName;
        return Adapter;
    };
}
const Basic = (props) => {
    const { prefixCls, className, children, tagName } = props, others = __rest(props, ["prefixCls", "className", "children", "tagName"]);
    const classString = classNames(prefixCls, className);
    return React.createElement(tagName, Object.assign({ className: classString }, others), children);
};
const BasicLayout = props => {
    const { direction } = React.useContext(ConfigContext);
    const [siders, setSiders] = React.useState([]);
    const { prefixCls, className, children, hasSider, tagName: Tag } = props, others = __rest(props, ["prefixCls", "className", "children", "hasSider", "tagName"]);
    const classString = classNames(prefixCls, {
        [`${prefixCls}-has-sider`]: typeof hasSider === 'boolean' ? hasSider : siders.length > 0,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    return (<LayoutContext.Provider value={{
        siderHook: {
            addSider: (id) => {
                setSiders(prev => [...prev, id]);
            },
            removeSider: (id) => {
                setSiders(prev => prev.filter(currentId => currentId !== id));
            },
        },
    }}>
      <Tag className={classString} {...others}>
        {children}
      </Tag>
    </LayoutContext.Provider>);
};
const Layout = generator({
    suffixCls: 'layout',
    tagName: 'section',
    displayName: 'Layout',
})(BasicLayout);
const Header = generator({
    suffixCls: 'layout-header',
    tagName: 'header',
    displayName: 'Header',
})(Basic);
const Footer = generator({
    suffixCls: 'layout-footer',
    tagName: 'footer',
    displayName: 'Footer',
})(Basic);
const Content = generator({
    suffixCls: 'layout-content',
    tagName: 'main',
    displayName: 'Content',
})(Basic);
export { Header, Footer, Content };
export default Layout;
